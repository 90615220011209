import React, { Component } from 'react';


interface Props {
    cents:number
}

interface State {
}

class FormatMoney extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {};
    }

    render() {
        let amount = (this.props.cents) / Math.pow(10, 2);
        return <span>${amount.toFixed(2)}</span>
    }
}

export {FormatMoney}