import React, {useEffect, useState} from "react";
import {CustomerLease} from "../gen";
import {leaseAPI} from "../api";
import {Link} from "react-router-dom";
import {DatetimeDisplay} from "../datetime_display";

function ListAllLeasesScreen() {
    const [leases, setLeases] = useState<Array<CustomerLease>>([]);

    const reloadAllLeases = () => {
        const leaseApi = new leaseAPI();
        leaseApi.leaseAPIServiceListLeases().then((response) => {
            if (response.data.success){
                setLeases(response.data.leases ?? [])
            } else {
                console.error(response.data.errorMessage)
                window.alert(response.data.errorMessage)
            }
        })
    }

    useEffect(reloadAllLeases, [])

    const rows = leases.map((lease) => {
        return <tr key={lease.txnRef}>
            <td><DatetimeDisplay timestamp={lease?.createdEpoch}/></td>
            <td>{lease.state}</td>
            <td>{lease.stock?.product?.name}</td>
            <td>
                <Link className={"btn btn-primary"} to={`lease/${lease.txnRef}`}>Details</Link>
            </td>
        </tr>
    })

    return <div className='container'>
        <h1>All Leases</h1>
        <Link className={"btn btn-primary"} to={`dashboard`}>Dashboard</Link>
        <table className="table">
            <thead>
            <tr>
                <th scope="col">Created At</th>
                <th scope="col">State</th>
                <th scope="col">Product</th>
                <th scope="col">Action</th>
            </tr>
            </thead>
            <tbody>
            {rows}
            </tbody>
        </table>
    </div>
}

export default (ListAllLeasesScreen);