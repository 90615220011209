import React, {useState} from "react";
import {authAPI} from "../api";
import {Redirect} from "react-router-dom";
import Input from 'react-phone-number-input/input'
import {IAuthenticationContext, withAuthentication} from "../authentication";

interface Props extends IAuthenticationContext {

}


function LoginScreen(props:Props) {
    const [mobileNumber, setMobileNumber] = useState<string>("");

    const [busy, setBusy] = useState(false);

    const [inputCode, setInputCode] = useState(false); // if we are waiting for input code input
    const [verificationCode, setVerificationCode] = useState("");


    const submitForm = (e:any) => {
        e.preventDefault();
        e.stopPropagation();
        setBusy(true);
        console.log(mobileNumber);

        const authApi = new authAPI();

        authApi.authenticationAPIServiceSignin({ mobile: mobileNumber }).then((resp) => {
            return resp.data
        }).then((resp) => {
            if (resp.success === true) {
                setInputCode(true);
                setVerificationCode("");
            }
        }).catch(() => {
            window.alert("Server error");
        }).finally(() => {
            setBusy(false);
        })
    }

    const verifyCode = (e:any) => {
        setBusy(true)
        e.preventDefault();
        e.stopPropagation();
        const authApi = new authAPI();
        authApi.authenticationAPIServiceVerifyAuthCode({ mobile: mobileNumber, authCode: verificationCode }).then((resp) => { return resp.data }).then((resp) => {
            if (resp.loginSuccess === true) {
                props.login(resp.authToken ?? "bug", mobileNumber)
            } else {
                window.alert(resp.errorMessage);
            }
        }).catch((e) => {
            console.log(e);
            window.alert("Server error");
        }).finally(() => {
            setBusy(false);
        });
    };

    const changeVerificationCode = (e: any) => {
        setVerificationCode(e.target.value)
    }

    const cancelInput = () => {
        setInputCode(false);
    }

    if (props.authentication.authenticated){
        return <Redirect to={"/"}/>
    }

    return <div className='container'>
        <h1>Login</h1>
        {inputCode ?
            <form onSubmit={verifyCode} className="form">
                <div className="form-group">
                    <input type="text" value={verificationCode} onChange={changeVerificationCode} placeholder="Verification Code" className="form-control" />

                </div>
                {busy ?
                    <div className="spinner-border" role="status">
                    </div> :
                    <input type="submit" className="btn btn-success" value="Verify" />
                }
                <br />
                <button onClick={cancelInput} value="Cancel" />
            </form>
            :
            (<form onSubmit={submitForm} className="form">
                <div className="form-floating mb-3">
                    <Input className="form-control border-0 border-bottom phoneInput"
                           international
                           country="AU"
                           value={mobileNumber}
                           onChange={(value)=>{setMobileNumber(String(value))}}/>
                    <label htmlFor="floatingInput" className={"textSecondary"}>Mobile Number</label>
                    <span className="countryCode textSecondary">+61</span>
                </div>
                {busy ?
                    <div className="spinner-border" role="status">
                    </div> :
                    <input type="submit" className="btn btn-success" value={"Login or Register"} />
                }
            </form>)
        }
    </div>
}


export default withAuthentication(LoginScreen);