import React, {useEffect, useState} from "react";
import {CustomerLease, CustomerLeaseState} from "../gen";
import {leaseAPI} from "../api";
import {Link, useParams} from "react-router-dom";
import {DatetimeDisplay} from "../datetime_display";
import {FormatMoney} from "../format_money";

function LeaseScreen() {
    const [lease, setLease] = useState<CustomerLease | null>(null);
    const params = useParams();
    console.log(params);

    const reloadLease = () => {
        const leaseApi = new leaseAPI();
        // @ts-ignore
        leaseApi.leaseAPIServiceQueryLease(params.txnRef).then((response) => {
            if (response.data.success){
                setLease(response.data.lease ?? null)
            } else {
                console.error(response.data.errorMessage)
                window.alert(response.data.errorMessage)
            }
        })
    }

    const terminateLease = (e:any) => {
        e.preventDefault();
        e.stopPropagation();

        const leaseApi = new leaseAPI();
        // @ts-ignore
        leaseApi.leaseAPIServiceTerminateLease(params.txnRef, {}).then((response) => {
            if (response.data.success){
                reloadLease();
            } else {
                console.error(response.data.errorMessage)
                window.alert(response.data.errorMessage)
            }
        })
    }

    const confirmReturn = (e:any) => {
        e.preventDefault();
        e.stopPropagation();

        const leaseApi = new leaseAPI();
        // @ts-ignore
        leaseApi.leaseAPIServiceConfirmReturn(params.txnRef, {}).then((response) => {
            if (response.data.success){
                reloadLease();
            } else {
                console.error(response.data.errorMessage)
                window.alert(response.data.errorMessage)
            }
        })
    }

    // @ts-ignore
    useEffect(reloadLease, [params.txnRef])
    return <div className='container'>
        <h1>Lease</h1>
        <Link className={"btn btn-primary"} to={`/lease`}>Lease History</Link>
        <hr/>
        {lease?.state === CustomerLeaseState.PendingPayment && <a className={"btn btn-success"} href={lease.paymentUrl}>
            Make Payment
        </a>}
        {lease?.state === CustomerLeaseState.Dispensed && <button className={"btn btn-success"} onClick={terminateLease}>
            Return Item
        </button>}
        {lease?.state === CustomerLeaseState.PendingReturn && <button className={"btn btn-success"} onClick={confirmReturn}>
            Confirm Return
        </button>}
        <table className="table">
            <thead>
            <tr>
                <th scope="col">Created At</th>
                <th scope="col">State</th>
                <th scope="col">Dispense At</th>
                <th scope="col">Returned At</th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <td><DatetimeDisplay timestamp={lease?.createdEpoch}/></td>
                <td>{lease?.state}</td>
                <td><DatetimeDisplay timestamp={lease?.dispenseEpoch}/></td>
                <td><DatetimeDisplay timestamp={lease?.returnEpoch}/></td>
            </tr>
            </tbody>
        </table>
        <h3>Product</h3>
        <table className="table">
            <thead>
            <tr>
                <th scope="col">Stock Code</th>
                <th scope="col">Product Name</th>
                <th scope="col">Product Img</th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <td>{lease?.stock?.code}</td>
                <td>{lease?.stock?.product?.name}</td>
                <td>
                    <img width={200} src={lease?.stock?.product?.imageUrl} alt=""/>
                </td>
            </tr>
            </tbody>
        </table>
        <h3>Dispense Cell</h3>
        <table className="table">
            <thead>
            <tr>
                <th scope="col">Locker Name</th>
                <th scope="col">Locker Cell</th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <td>{lease?.dispenseCell?.locker?.name}</td>
                <td>#{lease?.dispenseCell?.row}, {lease?.dispenseCell?.col}</td>
            </tr>
            </tbody>
        </table>
        {[CustomerLeaseState.PendingReturn, CustomerLeaseState.ReturnedConfirmed, undefined].includes(lease?.state) && <><h3>Return Cell</h3>
            <table className="table">
                <thead>
                <tr>
                    <th scope="col">Locker Name</th>
                    <th scope="col">Locker Cell</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td>{lease?.returnCell?.locker?.name}</td>
                    <td>#{lease?.returnCell?.row}, {lease?.returnCell?.col}</td>
                </tr>
                </tbody>
            </table>
        </>}
        <h3>Price</h3>
        <table className="table">
            <thead>
            <tr>
                <th scope="col">Price</th>
                <th scope="col">Deposit</th>
                <th scope="col">Surcharge</th>
                <th scope="col">Discount</th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <td><FormatMoney cents={lease?.price ?? 0}/></td>
                <td><FormatMoney cents={lease?.deposit ?? 0}/></td>
                <td><FormatMoney cents={lease?.surcharge ?? 0}/></td>
                <td><FormatMoney cents={lease?.discount ?? 0}/></td>
            </tr>
            </tbody>
        </table>
    </div>
}

export default (LeaseScreen);