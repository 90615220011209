import React, {useEffect, useState} from "react";
import {CustomerProduct} from "../gen";
import {leaseAPI, productAPI} from "../api";
import {Link, useHistory} from "react-router-dom";

function DashboardScreen() {
    const [products, setProducts] = useState<Array<CustomerProduct>>([]);

    const reloadProduct = () => {
        const productApi = new productAPI();
        productApi.productAPIServiceListProducts().then((response)=>{
            if (response.data.success){
                setProducts(response.data.products ?? [])
            } else {
                console.error(response.data.errorMessage)
                window.alert(response.data.errorMessage)
            }
        })
    }

    useEffect(reloadProduct, [])
    const history = useHistory()

    const rows = products.map((product) => {
        return <tr key={product.uuid}>
            <td>{product.name}</td>
            <td>
                <img width={200} src={product.imageUrl ?? ""} alt=""/>
            </td>
            <td>
                <button onClick={(e)=>{rentProduct(e, product.uuid ?? "")}}>Rent</button>
            </td>
        </tr>
    })

    const rentProduct = (e:any, productUuid: string) => {
        e.preventDefault();
        e.stopPropagation();

        const leaseApi = new leaseAPI();

        leaseApi.leaseAPIServiceStartLease({productUuid: productUuid}).then((response) => {
            if (response.data.success){
                history.push(`/lease/${response.data.leaseUuid}`)
            } else {
                console.error(response.data.errorMessage)
                window.alert(response.data.errorMessage)
            }
        })
    }

    return <div className='container'>
        <h1>Dashboard</h1>
        <Link className={"btn btn-primary"} to={`lease`}>Lease History</Link>
        <table className="table">
            <thead>
            <tr>
                <th scope="col">Name</th>
                <th scope="col">Img</th>
                <th scope="col">Action</th>
            </tr>
            </thead>
            <tbody>
            {rows}
            </tbody>
        </table>
    </div>
}

export default (DashboardScreen);