import {AuthenticationAPIServiceApi, Configuration, LeaseAPIServiceApi, ProductAPIServiceApi} from "./gen";
import axios from "axios";

export const authAPI = (function (){
    const authToken = localStorage.getItem("auth_token");

    const instance = axios.create({
        headers: {
            'content-type': 'application/json',
            "authorization": (authToken === null ? "" : `Bearer ${authToken}`)
        }
    });
    return new AuthenticationAPIServiceApi(new Configuration(), window.location.origin, instance);
} as unknown) as {new ():AuthenticationAPIServiceApi}

export const leaseAPI = (function (){
    const authToken = localStorage.getItem("auth_token");

    const instance = axios.create({
        headers: {
            'content-type': 'application/json',
            "authorization": (authToken === null ? "" : `Bearer ${authToken}`)
        }
    });
    return new LeaseAPIServiceApi(new Configuration(), window.location.origin, instance);
} as unknown) as {new ():LeaseAPIServiceApi}

export const productAPI = (function (){
    const authToken = localStorage.getItem("auth_token");

    const instance = axios.create({
        headers: {
            'content-type': 'application/json',
            "authorization": (authToken === null ? "" : `Bearer ${authToken}`)
        }
    });
    return new ProductAPIServiceApi(new Configuration(), window.location.origin, instance);
} as unknown) as {new ():ProductAPIServiceApi}