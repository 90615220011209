import React, {useEffect, useState} from 'react';
import './App.css';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from 'react-router-dom';
import LoginScreen from "./login/login";
import DashboardScreen from "./dashboard/dashboard";
import LeaseScreen from "./lease/lease";
import ListAllLeasesScreen from "./list_leases/list_leases";
import {Authentication, AuthenticationContext} from './authentication';
import {AuthenticationProtected} from "./authentication_protected";
import {productAPI} from "./api";

function App() {
  const [authentication, setAuthentication] = useState<Authentication>({
    authenticated:null,
    mobile:null,
    authentication_token:null
  });

  const login = (token:string, mobile:string)=>{
    console.log("login")
    localStorage.setItem("mobile", mobile);
    localStorage.setItem("auth_token", token);

    _verifyAuthToken(token, mobile)
  }

  const _verifyAuthToken = (token:string, mobile:string)=>{
    console.log("reload")
    console.log("token", token)
    console.log("mobile", mobile)
    if (token != null){
      verifyAuthToken(token).then((authed)=>{
        if (authed){
          console.log("authed")
          setAuthentication({
            authenticated: true,
            mobile: mobile,
            authentication_token: token
          })
        } else {
          console.log("unauthed")
          setAuthentication({
            authenticated: false,
            mobile: "",
            authentication_token: ""
          })
          localStorage.removeItem("mobile");
          localStorage.removeItem("auth_token");
        }
      }).catch((error)=>{
        console.log("error", error)
      })
    } else {
      setAuthentication({
        authenticated: false,
        mobile: "",
        authentication_token: ""
      })
      localStorage.removeItem("mobile");
      localStorage.removeItem("auth_token");
    }
  }

  const init = () => {
    let mobile = localStorage.getItem("mobile");
    let token = localStorage.getItem("auth_token");
    if (mobile != null && token != null){
      login(token, mobile)
    } else {
      setAuthentication({
        authenticated:false,
        mobile:null,
        authentication_token:null
      })
    }
  }

  async function verifyAuthToken(token: string) {
    const productApi = new productAPI();
    try {
      const result = await productApi.productAPIServiceListProducts()
      return (result.status === 200)
    } catch (e){
      return false
    }
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(init, [])

  return (
    <Router>
      <div className="App">
        <Switch>
          <AuthenticationContext.Provider value={{
            authentication:authentication,
            logout:()=>{},
            login:login
          }}>
            <Route exact path="/login">
              <LoginScreen />
            </Route>
            <Route exact path="/">
              <AuthenticationProtected>
                <DashboardScreen />
              </AuthenticationProtected>
            </Route>
            <Route exact path="/dashboard">
              <AuthenticationProtected>
                  <DashboardScreen />
              </AuthenticationProtected>
            </Route>
            <Route exact path="/lease">
              <AuthenticationProtected>
                  <ListAllLeasesScreen />
              </AuthenticationProtected>
            </Route>
            <Route exact path="/lease/:txnRef">
              <AuthenticationProtected>
                  <LeaseScreen />
              </AuthenticationProtected>
            </Route>
          </AuthenticationContext.Provider>
        </Switch>
      </div>
    </Router>
  );
}

export default App;
